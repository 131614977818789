import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { ErrorsModalInputs } from '../models/ErrorsModalInputs';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function ErrorsModal(errorsModalInputs: ErrorsModalInputs) {
    return (
        <React.Fragment>
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={errorsModalInputs.open}
                className="errors-modal"
            >
                <DialogTitle sx={{ m: 0, p: 2, fontSize: "20px" }} id="customized-dialog-title">
                    <b>Er ging iets mis</b>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {errorsModalInputs.errorsModalBodyText}
                    </Typography>
                </DialogContent>
                <DialogActions
                    className="error-background"
                    sx={{ justifyContent: "center" }}
                    style={{ padding: "unset" }}>
                    <Button onClick={errorsModalInputs.handleClose} sx={{
                        fontSize: "20px",
                        width: "100%",
                        padding: "14px 8px"
                    }} >
                        <b>Sluiten</b>
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
