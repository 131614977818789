import { Button, Divider, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MainPageProps } from "../models/MainPageProps";
import { Capacity } from "../models/Capacity";
import { Dictionary } from "express-serve-static-core";
import { MainPageData } from "../models/MainPageData";
import {Category, type SearchCertificateResponse} from "../models/SearchCertificateResponse"
import {SearchCertificateParams} from "../models/SearchCertificateParams";

export default function DetailsPage() {
    const nav = useNavigate();
    const location = useLocation();

    let mainPageData: MainPageData = location?.state?.mainPageData;

    useEffect(() => {
        if (!mainPageData) {
            nav("/");
        }
        log({
          DocumentNumber: getPropByName('document_number'),
          Categories: mainPageData.mainPageProps.selectedCapacitiesModels?.map(({id}) => id),
          VerifierName: mainPageData.mainPageProps.name,
          VerifierKvkNumber: mainPageData.mainPageProps.kvkNumber
        });
    });

    const log = async (
    {DocumentNumber, Categories, VerifierName, VerifierKvkNumber}: SearchCertificateParams
    ): Promise<void> => {
      const headers: Headers = new Headers()
      headers.set('Content-Type', 'application/json')
      headers.set('Accept', 'application/json')
      const bodyParameters = {
        DocumentNumber,
        Categories,
        VerifierName,
        VerifierKvkNumber
      };
      const request = new Request(`${process.env.REACT_APP_BACKEND_URL}` + 'api/Log', {
        method: 'POST',
        mode: "cors",
        headers: headers,
        body: JSON.stringify(bodyParameters)
      })
      fetch(request).then((response) => {
        if (!response.ok) {
          console.log(`[DetailsPage][Log] Log request has failed. ${response.status}: ${response.statusText}`);
        }
      });
    }
    const mainPageProps: MainPageProps = mainPageData.mainPageProps;
    const license: any = mainPageData.license;
    const licenseReceiveDate: Date = mainPageData.licenseReceiveDate;
    const capacityCodeExpirationDates: Dictionary<string> = {};
    const capacityCodeValidity: Dictionary<boolean> = {};
    if ((mainPageProps?.certificateNumber)) {
      (license?.categories as Category[])
        .forEach(category => capacityCodeValidity[category.category_code] = category.category_is_valid);
    } else {
      (JSON.parse(license['"capacities"']) as Capacity[])
        .forEach(cap => cap.codes.forEach(cap_code => capacityCodeExpirationDates[cap_code.code] = cap.expiry_date));
    }

    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localLocale = Intl.DateTimeFormat().resolvedOptions().locale;

    const getPropByName = (name: string) => (mainPageProps?.certificateNumber) ? license[name] : JSON.parse(license[`"${name}"`])
    
    const print = () => {
        const html2pdf = require("html2pdf.js");
        var source = window.document.getElementById("details-page-printable");
        var opt = {
            filename: getPropByName('document_number') + '.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 }
        };
        html2pdf(source, opt);
    };

  return (
    <Container sx={{ height: "100%" }} maxWidth={false}>
      <div id="details-page-printable">
        <Stack direction="row" justifyContent="end" sx={{ padding: "48px 64px 8px 0" }}>
          <img src="/Hobéon SKO logo 2021 RGB.png" width={196} />
        </Stack>
        <Container sx={{ maxWidth: "648px" }} maxWidth={false}>

          <h1 id="headerDetails">
            <a id="labelDocumentNumber"><b>Kopie Pyro-pass nummer </b></a>
            <a id="valueDocumentNumber">{getPropByName('document_number')}</a>
          </h1>

          <Stack>
            <Typography variant="h2" id="headerRequesterInfo"><strong>Gegevens aanvrager</strong></Typography>
            <Typography id="labelName"><b>Naam verkoper: </b>{mainPageProps.name}</Typography>
            <Typography id="labelKvkNumber"><b>KvK nummer bedrijf: </b>{mainPageProps.kvkNumber}</Typography>
          </Stack>

          <Divider sx={{ mb: 3, border: "none" }} variant="fullWidth" component="div" />

          <Stack>
            <Typography variant="h2" id="headerRequesterCertificate"><strong>Gegevens Pyro-pass</strong></Typography>
            {
              // Certificate number check:
              // When "document_is_valid" is "0", show No on all selected capacities
              // When "category_is_valid" is "0", show No on specific capacity
              // Otherwise show yes
              mainPageProps.selectedCapacitiesModels?.map(cap =>
                <Typography id={'label_' + cap.id}>
                  <a  id={'valueCapacityID_'+cap.id}><b>{cap.name}: </b></a>
                  <a id={'valueCapacityValidity_'+cap.id}>
                    {
                      (mainPageProps?.certificateNumber) ?
                        (!license?.document_is_valid) ? "No" :
                           (!!capacityCodeValidity[cap.id] ? (capacityCodeValidity[cap.id] ? "Yes" : "No") : "No")
                        :
                        (!!capacityCodeExpirationDates[cap.id]
                          ? (new Date(capacityCodeExpirationDates[cap.id]) > new Date() ? "Yes" : "No") : "No")                   
                    }
                  </a>
                  </Typography>
              )
            }
          </Stack>

          <Divider sx={{ mb: 14, border: "none" }} variant="fullWidth" component="div" />

          <Typography id="labelValidatedOn">Gecontroleerd op: {dateToCommonFormat(licenseReceiveDate)} om {licenseReceiveDate.toLocaleTimeString(localLocale, { hour: "2-digit", minute: "2-digit", hour12: false })} {localTimeZone}</Typography>

          <Divider sx={{ mb: 14, border: "none" }} variant="fullWidth" component="div" />

        </Container>
      </div>

      <Container sx={{ maxWidth: "648px" }} maxWidth={false}>
        <Button id="buttonGeneratePDF" variant="contained" onClick={print} sx={{ height: 64, width: "60%" }}>Genereer PDF</Button>
      </Container>

      <Divider sx={{ mb: 2, border: "none" }} variant="fullWidth" component="div" />

      <Container sx={{ maxWidth: "648px" }} maxWidth={false}>
        <Button id="buttonStartNewVerification" onClick={() => nav("/")} sx={{ height: 64, width: "60%" }}><i>Start nieuwe verificatie</i></Button>
      </Container>

    </Container>
  )
}

function dateToCommonFormat(date: Date): string {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear()
  ]
    .join('/');
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}